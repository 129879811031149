import React from "react"
import Template from "../Template"
import Link from "gatsby-link"
import style from "./style.module.scss"
import SEO from "../seo"

const Home = props => (
  <div className={style.containerPage}>
    <Template>
      <SEO title={"Home"} />
      <h1>Reliable Pet Care</h1>
      <h2>Caring for your pets like they’re our own.</h2>
      <Link to="#learn-more">Learn more</Link>
      <div className={style.howItWorks}>
        <h1>How it works</h1>
        <p>
          Reliable Pet Care is the pet care company we wanted for our own
          animals.
        </p>
        <div className={style.howItWorksBlocks} id="#learn-more">
          <div>a block</div>
          <div>a block</div>
          <div>a block</div>
        </div>
      </div>
    </Template>
  </div>
)

export default Home
