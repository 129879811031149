import { graphql } from "gatsby"
import Home from "../components/Home"

export default Home

export const query = graphql`
  query Home {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
